import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default function Footer() {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanitySiteSettings {
        careers
      }
    }
  `);

  return (
    <>
    <footer className="container grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 items-baseline mb-10 mt-16 lg:mt-32">
      <ul className="flex socials gap-8 uppercase text-[15px] tracking-[0.3em]">
        <li>
          <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Instagram</a>
        </li>
        <li>
          <a href="https://www.facebook.com/dimpatconstruction" target="_blank" rel="noreferrer">Facebook</a>
        </li>
        {data.sanitySiteSettings.careers && (
          <li>
            <a className="border-b-2 border-transparent transition-all ease-in-out duration-200 hover:border-current" href={data.sanitySiteSettings.careers} target="_blank" rel="noreferrer">Careers</a>
          </li>
        )}
      </ul>
      <ul className="flex flex-row md:items-center gap-4 md:gap-8 mt-8 md:mt-0">
        <li>
          <a href="https://www.mbav.com.au/" className="mbav" target="_blank" rel="noreferrer">
            <span className="sr-only">Master Builders Victoria</span>
          </a>
        </li>
        <li>
          <a href="https://www.vba.vic.gov.au/" className="rbp" target="_blank" rel="noreferrer">
            <span className="sr-only">Registered Building Practitioner</span>
          </a>
        </li>
        <li>
          <a href="https://www.vba.vic.gov.au/" className="vba" target="_blank" rel="noreferrer">
            <span className="sr-only">Victorian Building Autority</span>
          </a>
        </li>
      </ul>
    </footer>
    </>
  )
}